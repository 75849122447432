<template>
	<v-container
		fluid
		class="donor-detail-view mx-4"
		v-if="!isFetching && donor"
	>
		<a @click="$router.back()" class="ml-4">
			<i class="fas fa-chevron-left"> </i> Back</a
		>
		<h2 class="mt-4 ml-3" style="text-align:left">
			{{ donor.donor_name ? donor.donor_name : donor.user.email }}
		</h2>
		<p class="data-value ml-3" v-if="donor.donor_name">
			{{ donor.user.email }}
		</p>
		<v-row>
			<v-col cols="3">
				<p class="data-label">Donor ID</p>
				<p class="data-value">{{ donor.user_id }}</p>
			</v-col>
			<v-col cols="3">
				<p class="data-label">Phone</p>
				<p class="data-value">
					{{ donor.user.phone ? donor.user.phone : '-' }}
				</p>
			</v-col>
			<v-col cols="3">
				<p class="data-label">Signup Date</p>
				<p class="data-value">
					{{ simpleDateFormat(new Date(donor.created_at)) }}
				</p>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="3">
				<p class="data-label">Donor Status</p>
				<p class="data-value">{{ donor.status }}</p>
			</v-col>
			<v-col cols="3">
				<p class="data-label">Total Donated</p>
				<p class="data-value">{{ currencyFormat(totalDonated) }}</p>
			</v-col>
		</v-row>
		<v-row class="mx-4 ml-3 mt-6">
			<h3 class="pl-0 mt-2">
				Donation History
			</h3>
			<v-spacer></v-spacer>
			<Roundup-Button color="white" @click="exportDonations"
				>Export CSV</Roundup-Button
			>
		</v-row>
		<v-data-table
			:headers="columns"
			:items="donations"
			multi-sort
			class="elevation-1 mx-2 mt-6 table"
			loading-text="Fetching donations..."
		>
			<template v-slot:item.created_at="{ item }">
				{{ simpleDateFormat(new Date(item.created_at)) }}
			</template>
			<template v-slot:item.amount="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							{{ currencyFormat(item.amount) }}
						</div>
					</template>
					<span>
						Gross Amount: {{ currencyFormat(item.amount) }} <br />
						Credit Card Fees: {{ currencyFormat(item.stripe_fee) }}
						<br />
						Net Amount:
						{{ currencyFormat(item.amount - item.stripe_fee) }}
					</span>
				</v-tooltip>
			</template>
			<template v-slot:item.stripe_error="{ item }">
				{{ item.stripe_error ? 'Failed' : 'Success' }}
			</template>
			<template v-slot:item.merchant_match_multiplier="{ item }">
				{{
					item.merchant_match_multiplier
						? donor.donor_name
						: 'Anonymous'
				}}
			</template>
			<template v-slot:item.url_host="{ item }">
				{{ item.user.url_host || '' }}
			</template>
		</v-data-table>
	</v-container>
	<v-container fluid class="donor-detail-view mx-4" v-else>
		<v-skeleton-loader
			type="table-heading, list-item-two-line, image, table-tfoot"
		></v-skeleton-loader>
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
	get,
	numberFormat,
	simpleDateFormat,
	currencyFormat
} from '@/utilities'
import download from '@/mixins/download'

import UiButton from '../../ui/Button'
import Chart from '../../ui/Chart'
import Screen from '../../ui/Screen'

export default {
	name: 'donor-detail-view',
	components: { UiButton, Screen, Chart },
	mixins: [download],
	props: ['donorId'],
	data: () => {
		return {
			coreColumns: [
				{ text: 'Date', value: 'created_at' },
				{ text: 'Amount', value: 'amount' },
				{ text: 'Status', value: 'stripe_error' }
			]
		}
	},
	computed: {
		...mapState({
			donor: ({ donorDetail }) => donorDetail.item,
			donations: ({ donorDetail }) => donorDetail.donations,
			isFetching: ({ donorDetail }) => donorDetail.isFetching,
			totalDonated: ({ donorDetail }) => donorDetail.totalDonated,
			authToken: ({ me }) => me.token
		}),
		columns() {
			if (this.donorId && `${this.donorId}`.includes('merchant_')) {
				this.coreColumns.push({
					text: 'Donor',
					value: 'merchant_match_multiplier'
				})
			}
			if (this.donorId && `${this.donorId}`.includes('guest_')) {
				this.coreColumns.push({ text: 'URL', value: 'url' })
			}
			return this.coreColumns
		}
	},
	head: {
		title: {
			inner: 'Donor'
		}
	},
	methods: {
		...mapActions(['getDonor']),
		paramsChanged(params) {
			this.getDonors(params)
		},
		exportDonations() {
			window.analytics.track('download_donor_donation_history')

			this.postParameters(
				`/donors/${this.donorId}/donations/csv`,
				{},
				this.authToken
			)
		},
		get,
		numberFormat,
		simpleDateFormat,
		currencyFormat
	},
	mounted() {
		this.getDonor(this.donorId)
	}
}
</script>

<style lang="scss" scoped>
.donor-detail-view {
	.data-label {
		color: $roundup-dark-grey !important;
		text-transform: uppercase !important;
		font-size: 0.75rem !important;
		font-weight: 600 !important;
	}
	.data-value {
		font-size: 1.2rem !important;
		color: $roundup-charcoal !important;
	}
	.v-data-table {
		::v-deep .v-data-table-header {
			background-color: $roundup-bone !important;
			text-transform: uppercase !important;
		}
	}
}
</style>
